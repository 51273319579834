import React from 'react'
import SyncLoader from 'react-spinners/SyncLoader'

type Props = {
    active: boolean
}

const Loading = (props: Props) => {
  return (
    <div className={`loading fixed top-0 left-0 flex justify-center items-center z-50 backdrop-brightness-[0.25] h-screen w-screen${props.active ? '' : ' hidden'}`}>
      <SyncLoader color='#fff' />
    </div>
  )
}

export default Loading