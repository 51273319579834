import React, { useEffect, useState } from 'react'
import { BsCheck, BsClipboard, BsClipboardCheck } from 'react-icons/bs'
import { FaClipboard } from 'react-icons/fa'
import Button from './Button'
import { Clipboard as CapClipboard } from '@capacitor/clipboard';

type Props = {
  children: React.ReactNode,
  text: string,
}

const Clipboard = (props: Props) => {
  const [copied, setCopied] = useState<boolean>(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <Button className={`text-white inline-flex gap-3 ${copied ? ' bg-green-500' : 'bg-blue-500 transition-all duration-500'}`} onClick={() => {setCopied(true);CapClipboard.write({
      url: props.text
    })}}>
      {props.children}
      <BsClipboard className={`${copied && 'hidden opacity-0'}`} />
      <BsCheck className={`${!copied && 'hidden opacity-0'}`} />
    </Button>
  )
}

export default Clipboard