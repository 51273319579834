import React, { useEffect, useState } from 'react'
import Guest from '../../Layouts/Guest'
import Button from '../../Components/Button'
import Input from '../../Components/Input'
import { FcKey } from 'react-icons/fc';
import { forgotPasswordController } from '../../Controllers/Authentication';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Loading from '../../Containers/Loading';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import axios from 'axios';
import { host } from '../../Variables/Server';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const ReactSwal = withReactContent(Swal)
  const [cooldown, setCooldown] = useState<number>(0)
  const [pin, setPin] = useState<string>('')
  const navigate = useNavigate()
  const [sent, setSent] = useState<boolean>(false)

  useEffect(() => {
    const timeout = setInterval(() => {
      (async () => {
        const cooldownS = await SecureStoragePlugin.get({key: 'cooldown-forgot'}).then(val => Number(val.value)).catch(() => {
          setCooldown(0)
          return 0
        })
        if (cooldownS > 0) {
          setSent(true)
          const count = cooldownS-1
          setCooldown(count)
          SecureStoragePlugin.set({
            key: 'cooldown-forgot',
            value: count.toString()
          })
          if (!count) setCooldown(0)
        } else {
          // SecureStoragePlugin.remove({key: 'cooldown-forgot'})
          SecureStoragePlugin.remove({
            key: 'cooldown-forgot'
          })
        }
      })()
      
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [cooldown])
  

  const handleEmailSend = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    

    setIsLoading(true)
    const data = await forgotPasswordController('send', email)
    setIsLoading(false)
    SecureStoragePlugin.set({
      key: 'cooldown-forgot',
      value: '120'
    })

    if (data.status === 'success') {
      ReactSwal.fire(data.message, undefined, 'success')
        .then(() => setSent(true))
    } else {
      ReactSwal.fire(data.message, undefined, 'error')
    }
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    //
    const res = await axios.post(host + '/forgot-password/edit', {
      email,
      pin,
    })
      .then((res) => {
        console.log(res);
        return true
      })
      .catch((error) => {
        console.log(error)
        return false
      })

    console.log(res)

    if(!res){
      ReactSwal.fire('PIN or Email invalid', undefined, 'error')
    } else{
      SecureStoragePlugin.set({
        key: 'allowChange',
        value: 'true',
      })
      navigate(`/forgot-password/${email}/change`)
    }
  }

  return (
    <Guest>
      <Loading active={isLoading} />
      <div className="rounded-lg w-11/12 max-w-2xl p-3 gap-1 text-center">
        <FcKey className="mx-auto rotate-45" style={{fontSize: '99px'}} />
        <h1 className='uppercase text-xl font-semibold my-3'>
                Forgot Password
        </h1>
        <form className='flex flex-col gap-3' onSubmit={sent ? handleSubmit : handleEmailSend}>
          <div className="flex gap-2">
            <Input
              type={'email'}
              name={'emailForgot'}
              autoComplete={'false'}
              required
              autoFocus
              placeholder={'Insert your email\'s account for password recovery'}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            />  
            <Button level='primary' type={!sent ? 'submit' : 'button'} className='rounded-xl w-fit ml-auto disabled:bg-slate-200' disabled={!!cooldown} onClick={handleEmailSend}>
              {cooldown ? `${cooldown}s` : 'Send'}
            </Button>
          </div>
          <Input
            type={'passwordForgot'} 
            name='password' 
            placeholder='Input PIN'
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => setPin(e.target.value)}
            disabled={!sent}
          />
          <Button level='primary' type={sent ? 'submit' : 'button'} disabled={!sent} className='rounded-xl w-fit ml-auto'>New Password</Button>
        </form>
      </div>
    </Guest>
  )
}

export default ForgotPassword