import React, { Component } from 'react'

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string,
    className?: string,
    children?: string,
    [x: string]: unknown,
}

// type State = {}

class Textarea extends Component<Props> {
  state = {}

  handleResize (e:React.ChangeEvent<HTMLTextAreaElement>) {
    const target = e.target
    target.style.height = 'auto'
    target.style.height = target.scrollHeight + 'px'
  }

  render() {
    const {name, children, className, ...rest} = this.props
    return (
      <textarea name={name} className={`w-full p-3 border border-neutral-300 rounded-xl ${className}`}
        onInput={this.handleResize}
        {...rest}>{children}</textarea>
    )
  }
}

export default Textarea