import axios from "axios"
import Authorization from "../Variables/Authorization"
import { checklists, host } from "../Variables/Server"

const ChecklistsController = {
  index: async (schedule: string) => {
    const res = await axios.get(host + '/checklists?schedule=' + schedule, {
      headers: {
        Authorization: await Authorization()
      }
    })
      .then(res => {
        return res.data
      })
      .catch(err => {
        console.error(err)
        return err
      })
    return res
  },
  update: async (id:string, body: FormData) => {
    const res = await axios.put(host + checklists + '/' + id, body, {
      headers: {
        Authorization: await Authorization()
      }
    })
      .then(res => {
        return res.data
      })
      .catch(err => {
        console.log(err);
        return err
      })

    return res
  },
  deleteImage: async (id: string) => {
    const res = await axios.delete(host + checklists + '/image/' + id, {
      headers: {
        Authorization: await Authorization()
      }
    })
      .then(res => {
        console.log(res)
        return res.data
      })
      .catch(error => {
        return error.response.data
      })

    console.log(res)
    return res
  }
}

export default ChecklistsController