import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Logout from '../Components/Logout';
import { Home, Login, Register } from '../Pages';
import ForgotPassword from '../Pages/Authentication/ForgotPassword';
import ForgotPasswordChange from '../Pages/Authentication/ForgotPasswordChange';
import VerificationCheck from '../Pages/Authentication/VerificationCheck';
import VerificationRequest from '../Pages/Authentication/VerificationRequest';
import Profile from '../Pages/Profile';
import Schedule from '../Pages/Schedule/Item';
import Setting from '../Pages/Setting';

export default class RouteController extends React.Component{
  public render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/home' element={<Home />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/setting' element={<Setting />} />

          <Route path='/' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/logout' element={<Logout isButton={false} />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/forgot-password/:email/change' element={<ForgotPasswordChange />} />
          <Route path='/verify/:email/send' element={<VerificationRequest />} />
          <Route path='/verify/:encryption' element={<VerificationCheck />} />
          <Route path='/schedule/:id' element={<Schedule />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
