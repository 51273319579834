import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from '../../Components/Button'
import Loading from '../../Containers/Loading'
import { verifyController } from '../../Controllers/Authentication'
import Guest from '../../Layouts/Guest'

const VerificationRequest = () => {
  const ReactSwal = withReactContent(Swal)
  const [isLoading, setIsLoading] = useState(Boolean)
  const {email} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      const isVerified = await verifyController('check', undefined, undefined, email)
      
      if (isVerified) {
        navigate('/')
      }
    })()
  }, [])

  const requestVerify = async () => {
    setIsLoading(true)
    const data = await verifyController('send', undefined, undefined, email)
    setIsLoading(false)
    if (data.status === 'success') {
      ReactSwal.fire(data.message, undefined, 'success')
    } else {
      // ReactSwal.fire(data.message, undefined, 'error')
    }
  }

  return (
    <Guest>
      <Loading active={isLoading} />
      <div className="pt-24 w-screen">
        <div className="card w-11/12 max-w-2xl mx-auto bg-white p-3 shadow-md text-center">
          <p>
            Your email has not been verified, click the button below to verify
            your email.
          </p>
          <Button
            level="primary"
            className="rounded"
            onClick={() => requestVerify()}
          >
            Verify Email
          </Button>
        </div>
      </div>
    </Guest>
  )
}

export default VerificationRequest
