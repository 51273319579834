import React, {  } from 'react'

const Footer = () => {

  return (
    <footer className='w-full bg-primary text-center p-3 mt-5 rounded-t-md'>
      @2022 - Powered by <a href="https://greskit.com/" className="text-indigo-600"><b>Greskit</b></a>
    </footer>
  )
}

export default Footer