import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import ClipLoader from 'react-spinners/ClipLoader'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from '../Components/Button'
import Label from '../Components/Label'
import Textarea from '../Components/Textarea'
import ChecklistsController from '../Controllers/ChecklistsController'
import Spinner from '../Images/Spinner.gif'

type Props = {
    checklist_id: string,
    checklist_link_photo?: string,
    checklist_sesuai: number,
    checklist_minor: number,
    checklist_mayor: number,
    checklist_kritikal: number,
    checklist_temuan: string,
}

const ChecklistForm = (props: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [image, setImage] = useState<{
    file?: never,
    base64String?: Blob,
    dataURL: string
  }[]>([])
  const [isSesuai, setSesuai] = useState(false)
  const [isMinor, setMinor] = useState(false)
  const [isMayor, setMayor] = useState(false)
  const [isCritical, setCritical] = useState(false)
  const [temuan, setTemuan] = useState<string | ''>('')

  const assesment = useRef<HTMLDivElement>(null)
  const ReactSwal = withReactContent(Swal)

  useEffect(() => {
    setSesuai(props.checklist_sesuai===1)
    setMinor(props.checklist_minor===1)
    setMayor(props.checklist_mayor===1)
    setCritical(props.checklist_kritikal===1)
    setTemuan(props.checklist_temuan)
    
    if (props.checklist_link_photo) {
      setImage([{dataURL: props.checklist_link_photo, file: null}] as never[])
    }
    // setImage([{dataURL: 'http://localhost:3000/checklists/image/eF3puETNmwOf_3SlAs5xA', file: null}] as never[])
  }, [])

  const LoadingLoader = (
    <div className="h-screen w-screen fixed top-0 left-0 flex justify-center items-center backdrop-brightness-50 z-50">
      <ClipLoader size={100} />
    </div>
  )

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImage(imageList as never[]);
  };

  const handleAssesmentChange = (label:string, value:boolean) => {
    if (label === 'minor' && value === true) {
      setMinor(true);
      setMayor(false);
      setCritical(false);
    }
    if (label === 'mayor' && value === true) {
      setMinor(false);
      setMayor(true);
      setCritical(false);
    }
    if (label === 'critical' && value === true) {
      setMinor(false);
      setMayor(false);
      setCritical(true);
    }
    if (label === 'sesuai' && value === true) {
      setMinor(false);
      setMayor(false);
      setCritical(false);
    }
    console.log('handleAssessment')
  }

  const handleSubmit = async (e:SyntheticEvent) => {
    e.preventDefault();

    const formChecklist = new FormData();
    formChecklist.append('sesuai', isSesuai ? '1' : '0')
    formChecklist.append('minor', isMinor ? '1' : '0')
    formChecklist.append('mayor', isMayor ? '1' : '0')
    formChecklist.append('kritikal', isCritical ? '1' : '0')
    formChecklist.append('temuan', temuan)
    formChecklist.append('date_stamp', new Date().toISOString())

    console.log(image)
    if (image.length) {
      if (image[0].file !== null) {
        console.log('update')
        formChecklist.append('link_photo', image[0].file || '', ''+image)
      } else if (image[0].base64String !== null) {
        formChecklist.append('link_photo', image[0].base64String || '', ''+image)
      } else {
        console.log('same image')
        formChecklist.append('link_photo', '1')
      }
    } else {
      console.log('image removed')
      formChecklist.append('link_photo', '0')
    }

    setLoading(true)
    const resChecklists = await ChecklistsController.update(props.checklist_id, formChecklist)
    setLoading(false)

    console.log(resChecklists);
    if (!resChecklists.error) {
      // ReactSwal.fire(resChecklists.message, undefined, 'success')
    } else {
      ReactSwal.fire(resChecklists.message, undefined, 'error')
    }
  }

  const handleImageRemove = async (id: string) => {
    const res = await ChecklistsController.deleteImage(id)

    ReactSwal.fire(res.message, undefined, 'success')
  }

  return (
    <form id={`checklist-form-${props.checklist_id}`} className='flex flex-col gap-3' onSubmit={ handleSubmit }>
      {isLoading ? LoadingLoader : ''}
      <label>
        <div>Sesuai</div>
        <Switch
          onChange={(checked) => {
            setSesuai(checked)
            handleAssesmentChange('sesuai', checked)
          }}
          checked={isSesuai}
        />
      </label>
      <Label htmlFor='temuan'>Temuan</Label>
      <Textarea name='temuan' defaultValue={temuan} onChange={(e:ChangeEvent<HTMLTextAreaElement>) => setTemuan(e.target.value)} placeholder='Apa yang ditemukan?' />
      <ImageUploading
        allowNonImageType={false}
        multiple={false}
        value={image}
        onChange={onChange}
        maxFileSize={500000}
      >
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            {errors && (
              <div>
                {errors.maxFileSize && <span>Selected file size exceed maxFileSize (500kB)</span>}
              </div>
            )}
            {
              !imageList.length ? (
                <Menu
                  transition
                  menuButton={
                    <MenuButton
                      className={`px-5 py-2.5 
                      bg-primary text-black border-yellow-500 hover:bg-transparent 
                      rounded-lg
                      border hover:border-5 
                      transition-colors duration-300`}
                    >
                  Add Image
                    </MenuButton>
                  }
                >
                  <MenuItem onClick={onImageUpload}>
                    Upload
                  </MenuItem>
                  <MenuItem onClick={async () => {
                    const imageFromCamera = await Camera.getPhoto({
                      resultType: CameraResultType.DataUrl,
                      source: CameraSource.Camera,
                      saveToGallery: true,
                      allowEditing: true,
                      quality: 50
                    }).then(async res => {
                      console.log(res)
                      const blob = await fetch(res.dataUrl || '').then(res => res.blob())
                      return {
                        ...res,
                        dataURL: res.dataUrl,
                        file: blob
                      }
                    })
                    // console.log(atob(imageFromCamera))
                    setImage([imageFromCamera as never])
                    console.log(imageFromCamera)
                  }}>
                    Take From Camera
                  </MenuItem>
                </Menu>
              ) : null
            }
            {imageList.map((img, index) => (
              <div key={index} className="gap-3 w-full max-w-md flex h-36">
                <div className="flex flex-col justify-start gap-3">
                  <Menu
                    transition
                    menuButton={
                      <MenuButton
                        className={`px-5 py-2.5 
                      bg-primary text-black border-yellow-500 hover:bg-transparent 
                      rounded-lg
                      border hover:border-5 
                      transition-colors duration-300`}
                      >
                        Update
                      </MenuButton>
                    }
                  >
                    <MenuItem onClick={() => onImageUpdate(index)}>
                      Upload
                    </MenuItem>
                    <MenuItem onClick={async () => {
                      const imageFromCamera = await Camera.getPhoto({
                        resultType: CameraResultType.DataUrl,
                        source: CameraSource.Camera,
                        saveToGallery: true,
                        allowEditing: true,
                        quality: 50
                      }).then(async res => {
                        console.log(res)
                        const blob = await fetch(res.dataUrl || '').then(res => res.blob())
                        return {
                          ...res,
                          dataURL: res.dataUrl,
                          file: blob
                        }
                      })
                      const temp = image.slice(0)
                      temp.splice(index, 1, imageFromCamera as never)
                      setImage(temp)
                    }}>
                    Take From Camera
                    </MenuItem>
                  </Menu>
                  <Button
                    className='bg-red-500 text-white border border-red-500 transition-colors duration-300 hover:bg-white hover:text-red-500 rounded-lg'
                    onClick={() => {
                      ReactSwal.fire({
                        title: 'Are you sure you want to delete this image?',
                        showCancelButton: true,
                        icon: 'question'
                      })
                        .then(async ({isConfirmed}) => {
                          if (isConfirmed) {
                            onImageRemove(index)
                            await handleImageRemove(props.checklist_id)
                          }
                        })
                    }}>
                    Remove
                  </Button>
                </div>
                <Zoom>
                  <LazyLoadImage
                    placeholderSrc={Spinner}
                  
                    alt={`${props.checklist_id}-${index}`}
                    src={img.dataURL}
                    className={'aspect-video object-cover h-full'}
                    wrapperClassName={'row-span-3 object-cover'}
                  />
                </Zoom>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
      <Button type='submit' level='primary' className='rounded-lg w-fit ml-auto'>Save</Button>
    </form>
  )
}

ChecklistForm.defaultProps = {
  checklist_link_photo: '',
  schedule_name: '',
  schedule_description: ''
}

export default ChecklistForm