import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from '../Components/Button'
import Input from '../Components/Input'
import { loginController } from '../Controllers/Authentication'
import Loading from './Loading'

const LoginForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  })
  const [isLoading, setLoading] = useState<boolean>(false)
  const ReactSwal = withReactContent(Swal)
  const navigate = useNavigate()

  
  /* A loading screen that is displayed when the user clicks the login button. */
  

  /**
   * I'm trying to get the values of the input fields and pass them to the loginController function
   * @param e - React.SyntheticEvent - this is the event that is triggered when the form is submitted.
   */
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      username: { value: string };
      password: { value: string };
    };
    setLoading(true)
    const {user, status, message, token, suspect} = await loginController({
      email: target.username.value || formData.username,  
      username: target.username.value || formData.username,
      password: target.password.value || formData.password,
    })
    setLoading(false)

    if (status === 'success') {
      
      ReactSwal.fire({
        icon: 'success',
        title: message
      }).then(async () => {
        await SecureStoragePlugin.set({
          key: 'token',
          value: JSON.stringify(token)
        })
        await SecureStoragePlugin.set({
          key: 'user',
          value: JSON.stringify(user)
        })
        
        // window.location.replace('/')
        navigate('/home')
      })
    } else if (suspect === 'email') {
      const response = await ReactSwal.fire({
        icon: status,
        title: message,
        confirmButtonText: 'Verify Now',
      })

      console.log(response)
      if (response.isConfirmed) {
        navigate(`/verify/${target.username.value || formData.username}/send`, {replace:true})
      }
    }else {
      ReactSwal.fire({
        icon: status,
        title: message
      })
    }
  }

  return (
    <form className='flex flex-col gap-3' onSubmit={handleSubmit}>
      <Loading active={isLoading} />
      <><Input
        name={'username'}
        placeholder={'Username Or Email'}
        autoFocus
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, username: e.target.value })} /><Input
        name={'password'}
        type={'password'}
        placeholder={'Password'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, password: e.target.value })} /><div className="flex justify-between items-center">
        <Link to={'/forgot-password'} className='text-blue-500 underline hover:text-purple-500'>Forgot Password?</Link>
        <Button level='primary' type={'submit'} className='rounded-xl'>Login</Button>
      </div></>
    </form>
  )
}

export default LoginForm