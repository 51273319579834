import React from 'react'
import { Link } from 'react-router-dom'
import SMK3Logo from '../../Images/logo-smk3.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import RegisterForm from '../../Containers/RegisterForm'
import Guest from '../../Layouts/Guest'

const Register = () => {
  return (
    <Guest>
      <div className="card shadow rounded-lg w-11/12 max-w-2xl p-3 gap-1 border text-center bg-white">
        <LazyLoadImage src={SMK3Logo} alt="Logo SMK3" className={'h-12 mx-auto'}  />
        <h1 className='uppercase text-xl font-semibold'>
                register
        </h1>
        <hr className='my-3 bg-primary h-[2px]' />
        <RegisterForm />
        <span className='text-center'>Already have an account? <Link to={'/'} className='text-blue-500 underline hover:text-purple-500'>Login</Link></span>
      </div>
    </Guest>
  )
}

export default Register