const host = 'https://server.greskit.com/smk3/api'
// const host = 'http://localhost:3333'
const authCheck = '/auth/check'
const login = '/login'
const register = '/register'
const logout = '/logout'
const verifySend = '/verify/send'
const verify = '/verify'
const forgotPassword = '/forgot-password'

const schedules = '/schedules'

const checklists = '/checklists'

export {
  host,
  authCheck,
  login,
  register,
  logout,
  verifySend,
  verify,
  forgotPassword,
  schedules,
  checklists
}