import React from 'react'

type Props = {
    //
}

const Profile = (props: Props) => {
  return (
    <div>Profile</div>
  )
}

export default Profile