import React, { useEffect, useRef, useState } from 'react';
import { TbGridDots } from 'react-icons/tb';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SwiperCore, { Navigation, Pagination, Virtual } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '../../Components/Button';
import ButtonAnchor from '../../Components/ButtonAnchor';
import Navbar from '../../Containers/Navbar';
import ChecklistForm from '../../Containers/ChecklistForm';
import SchedulesController from '../../Controllers/SchedulesController';
import Main from '../../Layouts/Main';
import './Item.css';

SwiperCore.use([Virtual, Navigation, Pagination])

const Schedule = () => {
  const {id} = useParams() as {id: string}
  const [schedule, setSchedule] = useState(Object)
  const [checklists, setChecklists] = useState([])
  const ReactSwal = withReactContent(Swal)
  const navigate = useNavigate()
  const [swiperRef, setSwiperRef] = useState<SwiperCore>()
  const noDraggableArea = useRef<HTMLDivElement>(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const {schedule, checklists} = await SchedulesController.show(id)
      setLoading(false)
      
      setSchedule(schedule)
      setChecklists(checklists)
      console.log(checklists);
      
    })()

    const noDraggableAreaElement = noDraggableArea.current
    noDraggableAreaElement?.addEventListener('dragstart', (e) => {
      e.preventDefault()
    })
    // noDraggableAreaElement
  }, [id])

  const LoadingLoader = (
    <div className="h-96 w-full flex justify-center items-center">
      <ClipLoader size={100} />
    </div>
  )

  const handleScheduleDelete = async (id: string) => {
    const res = await SchedulesController.destroy(id)

    if (res.status === 'success') {
      ReactSwal.fire(res.message, undefined, 'success')
        .then(() => navigate(-1))
    } else {
      ReactSwal.fire(res.message, undefined, 'error')
    }
  }

  const slideTo = (index:number) => {
    swiperRef?.slideTo(index, 0);
  }

  return (
    <Main>
      <Navbar />
      <div className="px-5 grid grid-cols-1 gap-3">
        <div className="flex justify-between flex-col sm:flex-row gap-3">
          <ButtonAnchor to='/home' level='primary' className="rounded-lg">
            Back to Schedule
          </ButtonAnchor>
          <Button level='primary' className='bg-red-500 rounded-lg text-white hover:text-black' onClick={async () => {
            const confirmAlert = await ReactSwal.fire({
              title: 'Sure delete this?',
              icon: 'question',
              showCancelButton: true,
            })
            if (confirmAlert.isConfirmed) {
              handleScheduleDelete(schedule.id)
            }
          }}>Delete Schedule</Button>
        </div>
        <Select options={checklists.map((checklist:{
            element: string,
            criteria: string,
            id: string,
            id_element: string
          }) => {
          return {
            value: checklist.id,
            label: `${checklist.element} - ${checklist.criteria}`
          }
        })}
        className='z-10'
        onChange={
          e => {
            const index = checklists.findIndex((checklist: {id: string}) => {
              if (checklist.id === e?.value) {
                return true
              }
            })
            slideTo(index+1)
          }
        }
        />
        <div id="test" className='w-full text-center flex justify-center'>
          <div className="bullets"></div>
        </div>
      </div>
      { isLoading ? LoadingLoader : 
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => console.log('slide change')}
          onSwiper={setSwiperRef}
          pagination={{ 
            dynamicBullets: true,
            enabled: true,
            el: '.bullets',
            bulletElement: 'div'
          }}
          modules={[Pagination]}
          preventClicks={false}
          preventClicksPropagation={false}
          loop={true}
        >
          {
            checklists.length ? checklists.map((checklist:{
            id: string,
            element: string,
            sesuai: number,
            minor: number,
            mayor: number,
            kritikal: number,
            temuan: string,
            date_stamp: string,
            criteria: string,
            link_photo: string,
          }) => {
              return (
                <SwiperSlide key={checklist.id} className='flex flex-col items-center p-5 gap-3'>
                  <section className="criteria bg-white p-3 shadow-md rounded-md max-w-lg text-center">
                    <TbGridDots className='inline-block' />
                    <article className='text-center'>
                      <h1 className='text-xl font-bold'>{checklist.element}</h1>
                      <p>
                        {checklist.criteria}
                      </p>
                    </article>
                  </section>
                  <div className='w-full' ref={noDraggableArea} onDrag={() => console.log('asd')}>
                    <ChecklistForm
                      checklist_id={checklist.id}
                      checklist_sesuai={checklist.sesuai}
                      checklist_minor={checklist.minor}
                      checklist_mayor={checklist.mayor}
                      checklist_kritikal={checklist.kritikal}
                      checklist_temuan={checklist.temuan}
                      checklist_link_photo={checklist.link_photo}
                    />
                  </div>
                </SwiperSlide>
              )
            }) : null
          }
        </Swiper>
      }
    </Main>
  )
}

export default Schedule