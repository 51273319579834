import React from 'react'
import { Link } from 'react-router-dom'
import LoginForm from '../../Containers/LoginForm'
import SMK3Logo from '../../Images/logo-smk3.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Guest from '../../Layouts/Guest'
import Footer from '../../Containers/Footer'

const Login = () => {
  return (
    <Guest>
      <div>
        <div className="card shadow bg-white rounded-lg w-12/12 max-w-2xl p-3 gap-1 border text-center">
          <h1 className='uppercase text-xl font-semibold'>
              login
          </h1>
          <hr className='my-3 bg-primary h-[2px]' />
          <LazyLoadImage src={SMK3Logo} alt="Logo SMK3" className={'h-12 mx-auto'}  />
          <h5 className='text-center'>Sesuai PP 50 Tahun 2015</h5>
          <LoginForm />
          <div className='text-center pt-5 h-11'>Does&apos;t have any account? <Link to={'/register'} className='text-blue-500 underline hover:text-purple-500'>Register</Link></div>
        </div>
      </div>
    </Guest>
  )
}

export default Login