import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from '../Components/Button'
import Input from '../Components/Input'
import Textarea from '../Components/Textarea'
import { registerController } from '../Controllers/Authentication'
import isEmail from '../Helpers/IsEmail'
import 'react-phone-number-input/style.css'
import PhoneInput, {Value} from 'react-phone-number-input'
import Loading from './Loading'

// type Props = {}

const RegisterForm = () => {
  /* Setting the initial state of the form. */
  const [formData, setFormData] = useState<{
    name: string,
    username: string,
    email: string,
    address: string,
    phone: Value,
    company: string,
    password: string,
    password_confirmation: string,
  }>({
    name: '',
    username: '',
    email: '',
    address: '',
    phone: '',
    company: '',
    password: '',
    password_confirmation: '',
  })
  const ReactSwal = withReactContent(Swal)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [phone, setPhone] = useState<Value>()

  const navigate = useNavigate()

  const LoadingLoader = (
    <div className="h-screen w-screen fixed top-0 left-0 flex justify-center items-center backdrop-brightness-50 z-50">
      <ClipLoader size={100} />
    </div>
  )

  /* A function that is called when the form is submitted. It is checking if the email is valid and if
  the password and password confirmation are the same. */
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    
    if (!isEmail(formData.email)) {
      setFormData(formData)
      ReactSwal.fire('Email is invalid', undefined, 'warning')
      return``
    }

    if (formData.password !== formData.password_confirmation) {
      setFormData(formData)
      ReactSwal.fire('Password confirmation is invalid', undefined, 'warning')
      return
    }
    
    setLoading(true)
    const res = await registerController({...formData, phone: phone as Value})
    setLoading(false)
    console.log(res);
    
    if(res.status === 'success') {
      ReactSwal.fire(res.message, 'Check your inbox for email verification', 'success')
        .then(async () => {
          await SecureStoragePlugin.set({
            key: 'token',
            value: JSON.stringify(res.data.token)
          })
          await SecureStoragePlugin.set({
            key: 'user',
            value: JSON.stringify(res.data.user)
          })
          // window.location.replace('/home')
          navigate(`/verify/${formData.email}/send`)
        })
    } else {
      if (res.errors) {
        ReactSwal.fire(res.message, res.errors.map((error:{field: string, rule: string, message: string}) => {
          return `${error.field} (${error.rule}: ${error.message}\n`
        }).join(), 'error')
      } else {
        ReactSwal.fire(res.message, undefined, 'error')
      }
    }
  }

  return (
    <form className='flex flex-col gap-3' onSubmit={handleSubmit}>
      <>
        <Input required autoComplete='false' name={'name'} placeholder={'Full Name'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, name: e.target.value })} />
        <Input required autoComplete='false' name={'username'} placeholder={'Username'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, username: e.target.value })} />
        <Input required autoComplete='false' type={'email'} name={'email'} placeholder={'Email'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, email: e.target.value })} />
        <Textarea required autoComplete='false' name={'address'} placeholder={'Address'} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFormData({ ...formData, address: e.target.value })} />
        <PhoneInput
          placeholder="Enter phone number"
          value={phone}
          onChange={setPhone}
          defaultCountry='ID'
          className='border border-neutral-300 p-3 rounded-xl'
          smartCaret={true}
        />
        <Input required autoComplete='false' name={'company'} placeholder={'Company'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, company: e.target.value })} />
        <Input required autoComplete='false' type='password' name={'password'} placeholder={'Password'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, password: e.target.value })} />
        <Input required autoComplete='false' type='password' name={'password_confirmation'} placeholder={'Password Confirmation'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, password_confirmation: e.target.value })} />
        <div className="flex justify-end items-center">
          <Button type={'submit'} level='primary' className='rounded-xl'>Register</Button>
        </div>
        <Loading active={isLoading}/>
      </>
    </form>
  )
}

export default RegisterForm