import { App as CapacitorApp } from '@capacitor/app';
import PullToRefresh from 'react-simple-pull-to-refresh';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { isLoggedIn } from '../Controllers/Authentication';
import ClipLoader from 'react-spinners/ClipLoader';
import { Network } from '@capacitor/network';
import Swal from 'sweetalert2';


interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const Main = (props: Props) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (!(await Network.getStatus()).connected) {
        Swal.fire({
          title: 'No Internet',
          html: 'Connect to internet first',
          allowOutsideClick: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: false,
          showDenyButton: false,
          icon: 'error'
        })
      }
      
      const isLogin = await isLoggedIn() || false
      console.log(isLogin);
      
      if (!isLogin) {
        navigate('/');
        document.querySelector('#main')?.append('<p>asdasdasd</p>')
      }
    })()
    setLoading(false)

    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        window.history.back();
      }
    });

    
  }, []);
  const handleRefresh = async () => {
    navigate(0)
  }

  const LoadingLoader = (
    <div className="h-96 w-full flex justify-center items-center">
      <ClipLoader size={100} />
    </div>
  )

  return (
    <>
      {
        isLoading ? LoadingLoader : <PullToRefresh onRefresh={handleRefresh} className="bg-base min-h-screen">
          <main className={`min-h-screen ${props.className}`} {...props} >
            {/* <ToastContainer /> */}
            {props.children}
          </main>
        </PullToRefresh>
      }
    </>
  );
};

export default Main;