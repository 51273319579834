import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from '../../Components/Button'
import Input from '../../Components/Input'
import Loading from '../../Containers/Loading'
import { forgotPasswordController } from '../../Controllers/Authentication'
import Guest from '../../Layouts/Guest'

const ForgotPasswordChange = () => {
  const [isLoading, setIsLoading] = useState(false)
  const ReactSwal = withReactContent(Swal)
  const [isSuccess, setIsSuccess] = useState(false)
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    password_confirmation: ''
  })
  const {email} = useParams() as {email: string}

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const data = await SecureStoragePlugin.get({
        key: 'allowChange',
      })
      setIsLoading(false)

      if (data.value === 'true') {
        setFormData({...formData, email})
        setIsSuccess(true)
      } else {
        ReactSwal.fire('Restricted area', undefined, 'error')
          .then(() => {
            setTimeout(() => {
              navigate('/')
            }, 3000);
          })
      }
    })()
  }, [])

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    const target = e.target as typeof e.target & {
      password: { value: string };
      password_confirmation: { value: string };
    }

    if (formData.password !== formData.password) {
      ReactSwal.fire('Confirm password still not valid', undefined, 'warning')
    }

    const data = await forgotPasswordController('update', undefined, undefined, undefined, {
      email: formData.email,
      password: target.password.value || formData.password,
      password_confirmation: target.password_confirmation.value || formData.password_confirmation,
    })

    if (data.status === 'success') {
      ReactSwal.fire(data.message, undefined, 'success')
        .then(() => navigate('/'))
    } else {
      ReactSwal.fire(data.message, undefined, 'error')
    }
  }

  const formChangePassword = (
    <form className='flex flex-col gap-3' onSubmit={handleSubmit}>
      <Input
        type={'password'} 
        name='password' 
        placeholder='New Password'
        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setFormData({...formData, password: e.target.value})}
      />
      <Input
        type={'password'} 
        name='password_confirmation' 
        placeholder='Confirm New Password'
        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setFormData({...formData, password_confirmation: e.target.value})}
      />
      <Button type='submit' level='primary' className='rounded-xl w-fit ml-auto'>Submit</Button>
    </form>
  )

  return (
    <Guest>
      <Loading active={isLoading} />
      <div className="card p-3 w-11/12 max-w-2xl bg-white rounded-xl shadow-md">
        <h1 className='text-2xl font-bold text-center'>Change Your Password</h1>
        {
          isSuccess ? (
            formChangePassword
          ) : 'Change Password'
        }
      </div>
    </Guest>
  )
}

export default ForgotPasswordChange