import { Browser } from '@capacitor/browser'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import CheckboxTree, { Node } from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import { FaFile, FaFolder, FaFolderOpen, FaMinusSquare, FaRegPlusSquare } from 'react-icons/fa'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdIndeterminateCheckBox, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from '../Components/Button'
import Input from '../Components/Input'
import Textarea from '../Components/Textarea'
import SchedulesController from '../Controllers/SchedulesController'
import ScheduleInterface from '../Interfaces/SchedulesInterfaces'
import { schedulesUpdate } from '../Redux/counterSlice'
import Criterion from "../Variables/criterion.json"
import { host } from '../Variables/Server'

type Props = {
    schedule: ScheduleInterface
}

const EditScheduleForm = (props: Props) => {
  const [formData, setformData] = useState({
    startDate: props.schedule.date_start,
    finishDate: props.schedule.date_finish,
    scheduleName: props.schedule.name,
    scheduleDetail: props.schedule.schedule_desc
  })
  // const [criterion, setCriterion] = useState<object[]>([])
  const criterion = Criterion as unknown as Array<Node>
  const [checked, setChecked] = useState<string[]>(props.schedule.criterion)
  const [checkedError, setCheckedError] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<string[]>()

  // useEffect(() => {
  //   console.log()
  // }, [])

  const ReactSwal = withReactContent(Swal)
  const dispatch = useDispatch()

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      finishDate: { value: string };
      startDate: { value: string };
      scheduleName: { value: string };
      scheduleDetail: { value: string };
    };

    if (!checked?.length) {
      setCheckedError(true)
      return
    }
    setCheckedError(false)
    const data = {
      id: props.schedule.id,
      name: target.scheduleName.value || formData.scheduleName,
      date_start: new Date(target.startDate.value || formData.startDate).toISOString(),
      date_finish: new Date(target.finishDate.value || formData.finishDate).toISOString(),
      schedule_desc: target.scheduleDetail.value || formData.scheduleDetail,
      criterion: checked
    }
    console.log(data)
    
    const {data:res, schedule} = await SchedulesController.update(props.schedule.id, data)
    console.log(schedule)
    if(res.status === 'success') {
      ReactSwal.fire(res.message, undefined, 'success')
      dispatch(schedulesUpdate(schedule))
      // window.location.reload()
    } else {
      console.log(res);
      
      ReactSwal.fire(res.message, undefined, 'error')
    }
  }
  console.log(props.schedule.date_start, props.schedule.date_finish)
  return (
    <form className='grid grid-cols-1 gap-3'
      onSubmit={handleSubmit}
      onReset={() => {
        setformData({
          startDate: '',
          finishDate: '',
          scheduleName: '',
          scheduleDetail: ''
        })
        setChecked([])
      }}>
      <Input autoComplete='off' className='' name={'scheduleName'} placeholder={'Schedule Name'}
        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setformData({...formData, scheduleName: e.target.value})}
        value={formData.scheduleName}
        required
        autoFocus
      />
      <Textarea autoComplete='off' className='' name={'scheduleDetail'} placeholder={'Schedule Detail'} 
        onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => setformData({...formData, scheduleDetail: e.target.value})}
        value={formData.scheduleDetail}
        required
      />
      <div className="grid grid-cols-2 gap-3 text-start w-full">
        <div className="">
          <label htmlFor="startDate">Start Date</label>
          <Input autoComplete='off' className='w-full p-3 border rounded-xl' name='startDate' type='date' placeholder={'Start Date'}
            required
            max={formData.finishDate}
            value={DateTime.fromISO(formData.startDate).toFormat('yyyy-MM-dd')}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => setformData({...formData, startDate: e.target.value})}
          />
        </div>
        <div className="">
          <label htmlFor="finishDate">Finish Date</label>
          <Input autoComplete='off' className='w-full p-3 border rounded-xl' name='finishDate' type='date' placeholder={'Finish Date'}
            required
            value={DateTime.fromISO(formData.finishDate).toFormat('yyyy-MM-dd')}
            min={formData.startDate}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => setformData({...formData, finishDate: e.target.value})}
          />
        </div>
      </div>
      {checkedError && <span className='text-red-500'>at least choose a criteria</span>}
      <Button 
        onClick={() => Browser.open({
          url: `${host}/criterion`
        })}
        className=' w-fit px-0 py-0'
      >
        <BsFillQuestionCircleFill className='inline' /> Info Criteria
      </Button>
      <CheckboxTree
        nodes={criterion}
        checked={checked}
        expanded={expanded}
        onCheck={checked => setChecked(checked)}
        onExpand={expanded => setExpanded(expanded)}
        icons={{ 
          check: <MdCheckBox />,
          uncheck: <MdCheckBoxOutlineBlank />,
          halfCheck: <MdIndeterminateCheckBox />,
          expandClose: <MdKeyboardArrowRight />,
          expandOpen: <MdKeyboardArrowDown />,
          expandAll: <FaRegPlusSquare />,
          collapseAll: <FaMinusSquare />,
          parentClose: <FaFolder />,
          parentOpen: <FaFolderOpen />,
          leaf: <FaFile />
        }}
      />
      <div className="flex justify-end gap-3">
        <Button type='reset' level='secondary' onClick={() => setformData({
          startDate: '',
          finishDate: '',
          scheduleName: '',
          scheduleDetail: ''
        })} className='rounded-lg'>Reset</Button>
        <Button type={'submit'} level='primary' className='rounded-lg'>Send</Button>
      </div>
    </form>
  )
}
export default EditScheduleForm