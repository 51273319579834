import axios, { AxiosError } from 'axios'
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'
import React, { useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from '../Components/Button'
import Loading from '../Containers/Loading'
import Main from '../Layouts/Main'
import Authorization from '../Variables/Authorization'
import { host } from '../Variables/Server'

const Setting = () => {
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)

  const handleDeleteAccount = async () => {
    const ReactSwal = withReactContent(Swal)
    const {email} = JSON.parse((await SecureStoragePlugin.get({key: 'user'})).value) as {email: string}
    
    const {isConfirmed, value} = await ReactSwal.fire({
      title: 'Sure to delete your account permanently?',
      text: 'You can\'t restore it after deletion, even for us',
      inputLabel: 'Insert your account\'s email to confirm account deletion!',
      input: 'email',
      inputValidator: result => result === email ? '' : 'This is not your account\'s email',
      inputAutoTrim: true,
      inputAttributes: {
        placeholder: 'Insert your email'
      },
      showCancelButton: true,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#EF4444'
    })

    if (isConfirmed) {
      console.log(`${value} will delete`);
      await axios.delete(host+'/account', {
        headers: {
          Authorization: await Authorization(),
        }
      }).then(async () => {
        setLoading(true)
        await SecureStoragePlugin.clear()
        setLoading(false)
        Swal.fire('Deletion was successful', undefined, 'success')
        navigate('/')
      }).catch((error: AxiosError) => {
        console.log('Something error:', JSON.stringify(error));
        Swal.fire('Something error:', JSON.stringify(error), 'error')
      })
    }
  }

  return (
    <Main>
      <Loading active={isLoading} />
      <div className="max-w-md mx-auto flex flex-col pt-24 gap-3">
        <Button className='w-fit' onClick={() => navigate(-1)}>
          <BsChevronLeft className='text-2xl' />
        </Button>
        <Button className='bg-red-500 hover:bg-red-600 border border-red-500 text-white hover' onClick={handleDeleteAccount}>Delete Account</Button>
      </div>
    </Main>
  )
}

export default Setting