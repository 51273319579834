import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { logoutController } from '../Controllers/Authentication'
import Button from './Button'

const Level = {
  Primary: 'primary',
  Secondary: 'secondary',
} as const
  
type Level = typeof Level[keyof typeof Level]

type Props = {
    isButton?: boolean
    level?: Level
}

const Logout = (props: Props) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!props.isButton) {
      (async () => {
        const res = await logoutController()
        if (res.status === 'success') {
          await SecureStoragePlugin.clear()
          // window.location.replace('/')
          navigate('/')
        }
      })()
    }
  }, [])
  return (
    <Button level={props.level}>Logout</Button>
  )
}

export default Logout