import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loading from '../../Containers/Loading'
import { verifyController } from '../../Controllers/Authentication'
import Main from '../../Layouts/Main'
import React from 'react'
import Guest from '../../Layouts/Guest'

const VerificationCheck = () => {
  const [isLoading, setIsLoading] = useState(Boolean)
  const ReactSwal = withReactContent(Swal)
  const navigate = useNavigate()
  const signature = new URLSearchParams(window.location.search).get('signature') as string

  useEffect(() => {
    verify()
  }, [])

  const verify = async () => {
    setIsLoading(true)
    const data = await verifyController('verify', window.location.pathname, signature)
    console.log(data)
    setIsLoading(false)
    if (data.status === 'success') {
      await SecureStoragePlugin.set({
        key: 'user',
        value: data.data.user
      })
      ReactSwal.fire(data.message, undefined, 'success')
        .then(() => navigate('/'))
      
    } else {
      return 
      ReactSwal.fire(data.message, undefined, 'error')
    }
  }

  return (
    <Guest>
      <Loading active={isLoading} />
      <div className='h-screen flex justify-center items-center'>If you not redirected to main page or success pop-up not appear, your email verification is failed</div>
    </Guest>
  )
}

export default VerificationCheck