import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { Browser } from '@capacitor/browser';
import { MdManageSearch } from 'react-icons/md'
import { Provider, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from '../Components/Button'
import Input from '../Components/Input'
import CreateScheduleForm from '../Containers/CreateScheduleForm'
import EditScheduleForm from '../Containers/EditScheduleForm'
import Navbar from '../Containers/Navbar'
import SchedulesController from '../Controllers/SchedulesController'
import {} from 'luxon'
import Main from '../Layouts/Main'
import { useAppSelector } from '../Redux/hook'
import React from 'react'
import { store } from '../Redux/store'
import { schedulesFetch, selectCount } from '../Redux/counterSlice'
import { Link } from 'react-router-dom'
import { FiDownloadCloud, FiEdit } from 'react-icons/fi'
import { DateTime } from 'luxon'
import ScheduleInterface from '../Interfaces/SchedulesInterfaces'
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import { BsFillQuestionCircleFill, BsGlobe2, BsThreeDotsVertical } from 'react-icons/bs'
import 'animate.css'
import Clipboard from '../Components/Clipboard'
import { host } from '../Variables/Server'
import { HiOutlineChevronDown } from 'react-icons/hi'
import CriterionShowBox from '../Containers/CriterionShowBox';

const Home = () => {
  const ReactSwal = withReactContent(Swal)
  // const schedules= useAppSelector(Schedules)
  const dispatch = useDispatch()
  const schedules = useAppSelector(selectCount)
  const [inputFilter, setInputFilter] = useState<string>('')
  const [openList, setOpenList] = useState<number[]>([])
  
  useEffect(() => {
    (async () => {
      const {s} = await SchedulesController.index()
      
      dispatch(schedulesFetch(s))
    })()
  }, [])

  const filter = async (name:string|undefined) => {
    const {s} = await SchedulesController.index(name)
      
    dispatch(schedulesFetch(s))
  }

  console.log(schedules)

  const createSchedule = () => {
    ReactSwal.fire({
      title: 'Create Schedule',
      html: (
        <Provider store={store}>
          <CreateScheduleForm />
        </Provider>
      ),
      showConfirmButton: false,
      showCloseButton: true,
      color: 'black'
    })
  }

  const editSchedule = (schedule:ScheduleInterface) => {
    ReactSwal.fire({
      title: 'Edit Schedule',
      html: (
        <Provider store={store}>
          <EditScheduleForm schedule={{ 
            id: schedule.id,
            name: schedule.name,
            schedule_desc: schedule.schedule_desc,
            date_start: schedule.date_start,
            date_finish: schedule.date_finish,
            criterion: schedule.criterion
          }} />
        </Provider>
      ),
      showConfirmButton: false,
      showCloseButton: true,
      color: 'black',
    })
  }

  const emptySchedules = (
    <div className="flex justify-center items-center h-80">
      <h1 className='capitalize text-4xl text-center font-bold opacity-50'>
        Your schedule is empty
      </h1>
    </div>
  )

  const schedulesContainer = (
    <ul className='flex flex-col gap-5'>
      {
        schedules.length ? schedules.map((schedule, index) => (
          <li key={index}>
            <div className={`bg-white shadow-md p-3 flex justify-between items-center gap-3 ${openList.includes(index) ? 'rounded-t-md' : 'rounded-md'}`}>
              <div className="w-44">{
                DateTime.fromISO(schedule.date_finish).toFormat('dd LLL yyyy') !== 'Invalid DateTime' 
                  ? DateTime.fromISO(schedule.date_finish).toFormat('dd LLL yyyy') : 'No Date Finish'}
              </div>
              <Link to={`/schedule/${schedule.id}`} className="w-full text-blue-600 underline line-clamp-2">{schedule.name}</Link>
              
              <Button 
                className='w-min'
                onClick={() => {
                  if (!openList.includes(index)) {
                    setOpenList([index, ...openList])
                  } else {
                    setOpenList(openList.filter(item => item !== index))
                  }
                }}
              >
                <HiOutlineChevronDown className={`mx-auto transition-transform duration-300 ${openList.includes(index) ? 'rotate-180' : ''}`} />
              </Button>
              <Menu
                menuButton={
                  <MenuButton className={`px-5 py-2.5 transition-colors duration-300 rounded-lg`}>
                    <BsThreeDotsVertical />
                  </MenuButton>
                }
                transition
              >
                <MenuItem 
                  onClick={() => editSchedule({
                    id: schedule.id,
                    name: schedule.name,
                    schedule_desc: schedule.schedule_desc,
                    date_start: schedule.date_start,
                    date_finish: schedule.date_finish,
                    criterion: schedule.criterion})}
                >
                  <FiEdit className='w-11' /> Edit
                </MenuItem>
                <MenuItem onClick={() => ReactSwal.fire({
                  title: 'Download in XLSX',
                  showConfirmButton: false,
                  html: (
                    <>
                      <Clipboard text={`${host}/schedules/${schedule.id}/download`}>Copy To Clipboard</Clipboard>
                      <Button 
                        onClick={() => Browser.open({
                          url: `${host}/schedules/${schedule.id}/download`,
                          presentationStyle: 'popover',       
                        })}
                        className='inline-flex gap-3'
                      >
                      Open in Browser
                        <BsGlobe2 />
                      </Button>
                    </>
                  ),
                  showClass: {
                    popup: 'animate__animated animate__zoomIn animate__faster'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__zoomOut animate__faster'
                  }
                })}>
                  <FiDownloadCloud className='w-11' /> Download
                </MenuItem>
              </Menu>
            </div>
            
            <div className={`bg-white p-3 rounded-b-md shadow-md flex flex-col gap-3 ${openList.includes(index) ? '' : 'hidden'}`}>
              <div className="flex justify-center gap-3 border-2 border-primary rounded-full w-fit mx-auto p-3">
                <p>Start Date: {DateTime.fromISO(schedule.date_start).toFormat('dd LLL yyyy')}</p>
                <p>Finish Date: {DateTime.fromISO(schedule.date_finish).toFormat('dd LLL yyyy')}</p>
              </div>
              <div className="flex">
                <div className="flex-none">
                  <Button 
                    onClick={() => Browser.open({
                      url: `${host}/criterion`
                    })}
                    className=' w-fit px-0 py-0'
                  >
                    <BsFillQuestionCircleFill />
                  </Button>
                  <CriterionShowBox schedule={schedule} />
                </div>
                <p className='col-span-3 border-l-2 border-primary pl-3'>{schedule.schedule_desc}</p>
              </div>
            </div>
          </li>
        )) : ''
      }
    </ul>
  )

  return (
    <Main>
      <Navbar />
      {/* Body*/}
      <div className='px-5 grid grid-cols-1 gap-3'>
        <Button id='create-schedule' level='primary' className='rounded-lg' onClick={createSchedule}>
          <h4 className='px-16 py-0.5 text-black'>Create Schedule</h4>
        </Button>
        <form className="w-full flex gap-3" onSubmit={(e: SyntheticEvent) => {e.preventDefault();filter(inputFilter)}}>
          <Input
            name={'search'}
            placeholder={'Search'}
            onChange={(e:ChangeEvent<HTMLInputElement>) => setInputFilter(e.target.value)}
          />
          <Button type='submit' level='primary' className="max-h-11 aspect-square relative rounded-lg">
            <MdManageSearch className='text-2xl absolute -translate-x-1/2 -translate-y-1/2' />
          </Button>
        </form>
        {/* <div className="flex justify-between items-center gap-3">
          <span className='rounded-full p-3 border-2 border-primary'>Date Finish</span>
          <span>Title</span>
          <span></span>
          <span></span>
        </div> */}
        {
          schedules.length ? (schedulesContainer) : emptySchedules
        }
      </div>
    </Main>
  )
}

export default Home
