import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'
import Swal from 'sweetalert2'
import { authCheck, forgotPassword, host, login, logout, register, verify} from '../Variables/Server'

const Authorization = async () => {
  const token = await SecureStoragePlugin.get({key: 'token'})
    .then(data => {
      const value = JSON.parse(data.value)
      return value
    })
    .catch(() => {
      return {type: null, token: null}
    })
  
  console.log(token)
  return `${token.type} ${token.token}`
}

const isLoggedIn = async () => {
  try {
    const isLoggedIn = await axios.get(host + authCheck, {
      headers: {
        'Authorization': await Authorization()
      }
    })
      .then(res => res.data.data.isLoggedIn)
      .catch(error => error.response.data.data.isLoggedIn)	    
    return isLoggedIn
  } catch (error) {
    return false
  }
}

const loginController = async (body:{
    username?: string,
    email?: string,
    password: string,
}, config?: AxiosRequestConfig) => {    
  console.log(await Authorization());
  
  const {user, token, status, email, message, suspect} = await axios.post(host + login, body, config || {
    headers: {
      'Authorization' : await Authorization()
    },
  })
    .then(res => {
      console.log(res)
      return {
        user: res.data.data.user,
        token: res.data.data.token,
        status: res.data.status,
        message: res.data.message,
        suspect: undefined,
        email: undefined
      }
    }).catch(err => {
      console.log(err)
      if (err.response.data?.suspect === 'email') {
        return {
          suspect: err.response.data.suspect,
          message: err.response.data.message,
          user: null,
          token: null,
          status: null,
          email: err.response.data.email,
        }
      }
      return {
        user: err.response.data.data?.user || null,
        token: err.response.data.data?.token || null,
        status: err.response.data.status,
        message: err.response.data.message,
        suspect: undefined,
        email:  undefined,
      }
    })
  return {user, token, status, message, suspect, email}
}

const registerController = async (body:{
    name: string,
    username: string,
    email: string,
    password: string,
    password_confirmation: string,
    address: string,
    phone: string,
    company: string,
}) => {
  const res = await axios.post(host + register, body, {
    timeout: 1000 * 30
  })
    .then(res => res.data)
    .catch((err: AxiosError) => {
      console.error(err)
      Swal.fire({
        title: err.message,
        icon: 'error',
      })
      return err.response?.data
    })
  return res
}

const logoutController = async () => {
  const res = await axios.post(host + logout, {
    Headers: {
      'Authorization': await Authorization()
    }
  })
    .then(res => res.data)
    .catch(err => console.error(err))
  console.log(res)
  return res
}

const verifyController = async (type:('send'|'verify'|'check'), url?:string, signature?:string, email?:string) => {
  if (type === 'send') {
    const res = await axios.post(host + '/verify/' + email + '/send', undefined, {
      headers: {
        'Authorization': await Authorization()
      },
      timeout: 1000 * 30
    })
      .then(res => res.data)
      .catch((err: AxiosError) => {
        console.log(err)
        Swal.fire({
          title: err.message,
          icon: 'error',
        })
        return err.response?.data
      })
    return res
  } else if (type === 'verify') {
    const res = await axios.get(host + url, {
      params: {
        signature
      }
    })
      .then(res => res.data)
      .catch(err => err.response.data)
    return res
  } else if (type === 'check') {
    const res = await axios.get(`${host+verify}/${email}/check`, {
      headers: {
        'Authorization': await Authorization()
      }
    }).then(() => {
      return true
    }).catch(err => {
      console.log(err)
      return false
    })

    return res
  }
}

const forgotPasswordController = async (type:('send'|'verify'|'update'), email?: string, url?: string, signature?: string, body?: unknown) => {
  console.log(email)
  if (type === 'send') {
    const res = await axios.post(host + forgotPassword, {
      email
    })
      .then(res => {
        console.log(res);
        
        return res.data
      })
      .catch(err => {
        console.error(err);
        
        return err.response.data 
      })
    return res
  } else if (type === 'verify') {
    const res = await axios.get(host + url, {
      params: {
        signature
      }
    })
      .then(res => res.data)
      .catch(err => err.response.data)
    console.log(res)
    return res
  } else if (type === 'update') {
    const res = await axios.put(host + forgotPassword, body)
      .then(res => res.data)
      .catch(err => err.response.data)
    return res
  }
}

export {
  isLoggedIn,
  loginController,
  registerController,
  logoutController,
  verifyController,
  forgotPasswordController
}

