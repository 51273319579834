import React, { HTMLAttributes, useState } from 'react'
import CheckboxTree, { Node } from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import { FaFile, FaFolder, FaFolderOpen, FaMinusSquare, FaRegPlusSquare } from 'react-icons/fa'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdIndeterminateCheckBox, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md'
import ScheduleInterface from '../Interfaces/SchedulesInterfaces'
import Criterion from "../Variables/criterion.json"

interface Props extends HTMLAttributes<HTMLElement> {
    schedule: ScheduleInterface
}

const CriterionShowBox = (props: Props) => {
  const criterion = Criterion as unknown as Array<Node>
  const [checked, setChecked] = useState<string[]>(props.schedule.criterion)
  const [expanded, setExpanded] = useState<string[]>()

  // useEffect(() => {
    
  // })

  return (
    <CheckboxTree
      nodes={criterion}
      checked={checked}
      expanded={expanded}
      onCheck={checked => setChecked(checked)}
      onExpand={expanded => setExpanded(expanded)}
      icons={{ 
        check: <MdCheckBox />,
        uncheck: <MdCheckBoxOutlineBlank />,
        halfCheck: <MdIndeterminateCheckBox />,
        expandClose: <MdKeyboardArrowRight />,
        expandOpen: <MdKeyboardArrowDown />,
        expandAll: <FaRegPlusSquare />,
        collapseAll: <FaMinusSquare />,
        parentClose: <FaFolder />,
        parentOpen: <FaFolderOpen />,
        leaf: <FaFile />
      }}
    />
  )
}

export default CriterionShowBox