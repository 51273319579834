import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PullToRefresh from 'react-simple-pull-to-refresh'
import Swal from 'sweetalert2'
import Footer from '../Containers/Footer'
import Loading from '../Containers/Loading'
import { isLoggedIn } from '../Controllers/Authentication'

type Props = {
    children: React.ReactNode
}

const Guest = (props: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    
    (async () => {
      setLoading(true)
      if (await isLoggedIn()) {
        setLoading(false)
        window.location.replace('/home')
      }
      setLoading(false)
    })()
  },[])

  const handleRefresh = async () => {
    navigate(0)
  }
  
  return (
    <PullToRefresh onRefresh={handleRefresh} >
      <div className="bg-base min-h-screen">
        <Loading active={isLoading} />
        <div 
          className={`w-screen min-h-screen top-0 left-0  bg-base flex justify-center items-center`}
          // style={{
          //   height: screenHeight
          // }}
        >
          {props.children}
        </div>
        <Footer />
      </div>
    </PullToRefresh>
  )
}

export default Guest