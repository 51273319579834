import axios, { AxiosError } from "axios"
import Swal from "sweetalert2"
import ChecklistInterface from "../Interfaces/ChecklistsInterfaces"
import ScheduleInterface from "../Interfaces/SchedulesInterfaces"
import Authorization from "../Variables/Authorization"
import { host, schedules } from "../Variables/Server"

const SchedulesController = {
  index: async (name?:string) => {
    const {schedules: s, checklists: c} = await axios.get(`${host + schedules}${name ? '?name=' + name : ''}`, {
      headers: {
        'Authorization': await Authorization()
      }
    })
      .then(res => {
        return {
          schedules: res.data.data.schedules as never[] as ScheduleInterface[],
          checklists: res.data.data.checklists as never[] as ChecklistInterface[]
        }
      })
      .catch(err => {
        console.error(err)
        return {
          schedules: undefined,
          checklists: undefined
        }
      })
    console.log({s, c});
        
    return {s, c}
  },
  show: async (id: string) => {
    const res = await axios.get(host+schedules+'/'+id, {
      headers: {
        Authorization: await Authorization()
      }
    })
      .then(res => {
        return {
          schedule: res.data.data.schedule,
          checklists: res.data.data.checklists
        }
      })
      .catch(err => console.error(err))
            
    return res as {schedule: never, checklists: never[]}
  },

  create: async () => {
    const res = await axios.get(host + schedules + '/create', {
      headers: {
        Authorization: await Authorization()
      }
    })
      .then(res => {
        return res.data.data.criterion
      })
      .catch((err:AxiosError) => {
        Swal.fire(err.code, err.message, 'error')
        console.error(err)
      })

    return res
  },

  store: async (body:{
        name: string,
        date_start: string,
        date_finish: string,
        schedule_desc: string,
    }) => {
    const res = await axios.post(host + schedules, body, {
      headers: {
        Authorization: await Authorization()
      }
    })
      .then(res => {
        console.log(res);
            
        return {
          data: res.data,
          schedule: res.data.data.schedule
        }
      })
      .catch(err => {
        console.error(err);
        return err.response.data
      })
    console.log(res);
        
    return res
  },
  destroy: async (id:string) => {
    const res = await axios.delete(host + schedules + '/' + id, {
      headers: {
        Authorization: await Authorization()
      }
    })
      .then(res => {
        return res.data
      })
      .catch(err => {
        console.log(err);
        return err
      })

    return res
  },
  update: async (id:string, body:ScheduleInterface) => {
    const res = await axios.put(host + schedules + '/' + id, body, {
      headers: {
        Authorization: await Authorization()
      },
      validateStatus: () => true
    })
      .then(res => {
        console.log(res.data.data)
        return {
          data: res.data,
          schedule: res.data.data.schedule
        }
      })
      .catch(err => {
        console.log(err);
        return {
          data: err,
          schedule: []
        }
      })

    return res
  }
}

export default SchedulesController