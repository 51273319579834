import { SecureStoragePlugin } from "capacitor-secure-storage-plugin"

const Authorization = async () => {
  const token = await SecureStoragePlugin.get({key: 'token'})
    .then(data => {
      const value = JSON.parse(data.value)
      return value
    })
    .catch(() => {
      return {type: null, token: null}
    })

  console.log(token)
  return `${token.type} ${token.token}`
}

export default Authorization