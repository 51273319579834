import { Browser } from '@capacitor/browser';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import React from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// type Props = {}

const Navbar = () => {
  const navigate = useNavigate()

  return (
    <nav className='flex justify-end'>
      <Menu 
        menuButton={
          <MenuButton className={'px-5 py-2.5'}>
            <HiDotsVertical className='text-3xl text-yellow-500' />
          </MenuButton>
        } 
        transition
      >
        <MenuItem onClick={() => Browser.open({url: 'https://smk3.greskit.com/docs/guide', presentationStyle: 'popover', windowName: 'Guide'})}>Guide</MenuItem>
        <MenuItem onClick={() => navigate('/setting')}>Setting</MenuItem>
        <MenuItem onClick={() => Swal.fire('Are you sure to log out', undefined, 'question')
          .then(val => {
            if(val.isConfirmed) {
              navigate('/logout')
            }
          })}>Logout</MenuItem>
      </Menu>
    </nav>
  )
}

export default Navbar